import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts';
import Terms from '../components/Terms/Terms';

const TermsPage = (): React.ReactElement => {
  return (
    <Layout>
      <Terms />
    </Layout>
  );
};

export default TermsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["translation", "termsPage", "termsPageStatic"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
