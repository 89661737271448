import React from 'react';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import StaticPage from '../StaticPage/StaticPage';
import { HOME_ROUTE, PRICING_ROUTE, SUPPORT_EMAIL_ROUTE } from '../../const/routes.const';

const Terms = (): React.ReactElement => {
  const { language } = useI18next();
  const paymentsBalanceBlogLinks = {
    ru: 'https://docs.octobrowser.net/payments/balance/',
    en: 'https://docs.octobrowser.net/en/payments/balance/'
  };

  return (
    <StaticPage
      date={<Trans>Last updated January 4, 2023</Trans>}
      title={<Trans>TERMS OF USE</Trans>}
    >
      <p>
        <Trans>
          BLACKBIRD SECURE BROWSING LTD (limited liability company registered Unit 1058, Second Floor,
          6 Market Place, London, Fitzrovia, United Kingdom, W1W 8AF) and its affiliates, authorized
          resellers or distributors (“OCTO”, “we” or “us”) offer you to use this website or access any
          OCTO’s software, products, and services or any other digital content (“Software”).
        </Trans>
      </p>
      <p>
        <Trans
          i18nKey="Use of the Software is also governed by OCTO’s Privacy Policy. These Terms of Use and our
        Privacy Policy are available on the OCTO’s website. These Terms of Use are a legal agreement
        between you, as a current or prospective user of OCTO (“you”, “your”) and us and govern your
        use of OCTO’s Software. You must agree to these Terms of Use and the Privacy Policy before
        accessing and using the Software. By using the Software in any manner, you agree to be bound
        by these documents."
        >
          Use of the Software is also governed by OCTO’s Privacy Policy. These Terms of Use and our
          Privacy Policy are available on the OCTO’s <Link to={HOME_ROUTE}>website</Link>. These Terms
          of Use are a legal agreement between you, as a current or prospective user of OCTO (“you”,
          “your”) and us and govern your use of OCTO’s Software. You must agree to these Terms of Use
          and the Privacy Policy before accessing and using the Software. By using the Software in any
          manner, you agree to be bound by these documents.
        </Trans>
      </p>
      <h5><Trans>Accessing and using our Software</Trans></h5>
      <p>
        <Trans>
          Software that may be downloaded from OCTO’s website is licensed subject to the click-on End
          User License Agreement (“EULA”) which you agree to at the time of download or use. You may
          not install any Software that is accompanied by EULA or includes EULA if you have not agreed
          to the applicable terms of the EULA. The Software is available for download exclusively for
          use by end-users in accordance with the EULA and (or) these Terms of Use. Except as
          expressly permitted in EULA or as expressly permitted by applicable law you agree that you
          will not sell, rent, lend, transfer, transmit, modify, adapt, translate, prepare derivative
          works, decompile, deconstruct, disassemble or attempt to extract source code from the
          Software.
        </Trans>
      </p>
      <p>
        <Trans>
          You will be required to create an account in order to download, access or use the Software.
          In this regard, we ask you to provide a valid and proper email address and (or) your login
          in the Telegram messenger. You agree that you must take all steps necessary to protect your
          log-in details and keep them confidential. You agree that you may not give your log-in
          details to anyone else and that you may not allow anyone else to use your log-in details or
          your account.
        </Trans>
      </p>
      <p>
        <Trans>
          Supplemental documents that may be posted on the website from time to time are hereby
          expressly incorporated herein by reference. We reserve the right, in our sole discretion, to
          make changes to these Terms of Use at any time and for any reason. We will alert you about
          any changes by updating the “Last updated” date of these Terms of Use, and you waive any
          right to receive specific notice of each such change. Please ensure that you check the
          applicable Terms every time you use our website so that you understand which Terms of Use
          apply.
        </Trans>
      </p>
      <h5>
        <Trans>Subscription and Payments</Trans>
      </h5>
      <p>
        <Trans i18nKey="OCTO offers you the use of the Software on the basis of a paid subscription. Prices and subscription packages can be found here.">
          OCTO offers you the use of the Software on the basis of a paid subscription. Prices and
          subscription packages can be found <Link to={PRICING_ROUTE}>here</Link>.
        </Trans>
      </p>
      <p>
        <Trans>
          In order to purchase a subscription, you need to buy a Token - a virtual currency where the
          price of 1 Token is equal to 1 Euro.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Before making any payments or purchases, please review the terms of account replenishment and payments on this page. Feel free to contact us if you still have any questions via support@octobrowser.net. We do not provide refunds on any payments or purchases made by you.">
          Before making any payments or purchases, please review the terms of account replenishment
          and payments on this <a
          href={paymentsBalanceBlogLinks[language as keyof typeof paymentsBalanceBlogLinks] || paymentsBalanceBlogLinks.en}
          target="_blank"
          rel="noopener noreferrer"
        >
          page
        </a>
          . Feel free to contact us if you still have any questions via <a href={`mailto:${SUPPORT_EMAIL_ROUTE}`}>{SUPPORT_EMAIL_ROUTE}</a>. We do not provide
          refunds on any payments or purchases made by you.
        </Trans>
      </p>
      <p>
        <Trans>
          You understand and accept that once you have purchased Tokens, they have no monetary value,
          do not represent any stored value and cannot be exchanged for real money. You accept that
          Tokens may not be transferred to anyone else and you agree that you will not transfer or
          attempt to transfer any Tokens. You also hereby agree and confirm that unused Tokens are not
          subject to reverse exchange and refund.
        </Trans>
      </p>
      <p>
        <Trans>
          We reserve the right to choose the forms of payment accepted for OCTO Software, to refuse
          select forms of payment, to refuse service to anyone.
        </Trans>
      </p>
      <p>
        <Trans>
          We may add or remove payment processing methods at its sole discretion and without notice to
          Customer.
        </Trans>
      </p>
      <h5>
        <Trans>Intellectual Property Rights</Trans>
      </h5>
      <p>
        <Trans>
          Intellectual Property Rights includes patents, copyrights (including rights in designs,
          trademarks, service marks, logos, applications for any of the above, know-how, trade
          secrets, domain names, URL, trade names, and any other intellectual or industrial property
          rights (and any licenses in connection with any of the same) including moral rights, in each
          case whether registered or unregistered or capable or incapable of registration and
          including applications for registration and all rights and forms of protection having
          equivalent or similar effect anywhere in the world now or hereafter owned by, developed by
          or on behalf of OCTO.
        </Trans>
      </p>
      <p>
        <Trans>
          You agree and acknowledge that all the rights, title, and interest in and to the
          Intellectual Property, including moral rights, are solely owned by OCTO.
        </Trans>
      </p>
      <p>
        <Trans>
          You must not use any part of the Software and (or) content on our website for commercial
          purposes without obtaining a license to do so from us or our licensors.
        </Trans>
      </p>
      <p>
        <Trans>
          Breach of this section shall be considered as a material breach of these Terms of Use.
        </Trans>
      </p>
      <h5>
        <Trans>Privacy Policy and Cookies</Trans>
      </h5>
      <p>
        <Trans>
          OCTO’s Privacy Policy describes how we handle information that we receive from you in
          connection with our Software. Please make sure that you have read and understood the terms
          of our Privacy Policy.
        </Trans>
      </p>
      <h5>
        <Trans>Term and Termination</Trans>
      </h5>
      <p>
        <Trans>
          These Terms of Use are effective upon your acceptance of these Terms of Use, or upon your
          downloading, accessing or otherwise using the Software and run for an indefinite term unless
          terminated by you or OCTO as set forth in this section.
        </Trans>
      </p>
      <p>
        <Trans>
          We may terminate these Terms of Use, or suspend or terminate your account at any time in
          case of:
        </Trans>
      </p>
      <ul>
        <li>
          <Trans>
            your violation any restrictions or clauses of these Terms of Use or applicable law;
          </Trans>
        </li>
        <li>
          <Trans>system failure or any other circumstances beyond OCTO's control.</Trans>
        </li>
      </ul>
      <p>
        <Trans>
          We will do our best to send you advance notification, but we cannot guarantee that we will
          be able to send a notification under any circumstances.
        </Trans>
      </p>
      <p>
        <Trans>
          These Terms of Use will be terminated immediately and automatically without any notice if
          you violate any of the terms and conditions of these Terms of Use.
        </Trans>
      </p>
      <p>
        <Trans>
          If we terminate or suspend your account for any reason, you are prohibited from registering
          and creating a new account under your name, a fake or borrowed name, or the name of any
          third party, even if you may be acting on behalf of the third party.
        </Trans>
      </p>
      <p>
        <Trans>
          In addition to terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil, criminal, and
          injunctive redress.
        </Trans>
      </p>
      <h5>
        <Trans>Indemnification</Trans>
      </h5>
      <p>
        <Trans>
          You agree to indemnify, defend, and hold harmless OCTO, its affiliates, directors, officers,
          employees, and agents from and against any and all liabilities arising from any claims due
          to, arising out of, or relating in any way to (a) any breach of these Terms of Use by you;
          or (b) any gross negligence or willful misconduct of you.
        </Trans>
      </p>
      <p>
        <Trans>
          OCTO reserves the right, at your expense, to assume the exclusive defense and control of any
          matter for which you are required to indemnify OCTO.
        </Trans>
      </p>
      <h5>
        <Trans>Limitation of Liability</Trans>
      </h5>
      <p>
        <Trans>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER OCTO, NOR THEIR AFFILIATES, WILL
          BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
          PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS, REVENUE, OR BUSINESS, WHETHER
          INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, GOODWILL, OR OTHER INTANGIBLE LOSSES,
          UNDER THIS TERMS OF USE, EVEN IF OCTO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          ACCESS TO, AND USE OF, ANY SOFTWARE ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE
          SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA
          RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OCTO’S
          LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS TERMS OF USE OR OCTO’S
          PRIVACY POLICY (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION) WILL AT
          ALL TIMES BE LIMITED TO THE AMOUNT YOU’VE PAID OCTO IN THE PRIOR 12 MONTHS (IF ANY).
        </Trans>
      </p>
      <h5>
        <Trans>Disclaimers</Trans>
      </h5>
      <p>
        <Trans>
          We do not guarantee that Software is free from defects. Our Software is provided “as is”,
          and you use the Software at your own risk. We make no warranties as to performance,
          merchantability, fitness for a particular purpose, or any other warranties whether expressed
          or implied. No oral or written communication from or information provided by OCTO shall
          create a warranty. Under no circumstances shall OCTO be liable for direct, indirect,
          special, incidental, or consequential damages resulting from the use, misuse, or inability
          to use the Software, even if OCTO has been advised of the possibility of such damages. We do
          not warrant that our Software or its functioning or the content and material made available
          thereby will be timely, secure, uninterrupted, competent, useful or error-free, that defects
          will be corrected, or that our Software or the servers that make our Software available are
          free of viruses or other harmful components.
        </Trans>
      </p>
      <p>
        <Trans>
          The use of our Software is at your sole risk and you assume full responsibility for any
          costs associated with your use of our Software. We will not be liable for any damages of any
          kind related to the use of our website.
        </Trans>
      </p>
      <h5>
        <Trans>Confidentiality</Trans>
      </h5>
      <p>
        <Trans>
          Confidential Information means information of commercial value which is kept confidential by
          OCTO, which shall not come into the public in accordance with the Terms of Use and disclosed
          by OCTO or its directors, employees and professional advisers to you, whether disclosed
          orally or in writing.
        </Trans>
      </p>
      <p>
        <Trans>
          You shall use all commercially reasonable efforts to protect Confidential Information from
          any harm, tampering, unauthorized access, sabotage, exploitation, manipulation,
          modification, interference, misuse, misappropriation, copying or disclosure.
        </Trans>
      </p>
      <p>
        <Trans>
          Your rights and obligations under this clause shall not be applied to the Confidential
          Information to the extent that: (i) it becomes publicly known for reasons not connected with
          breach of this clause; (ii) its disclosure has been approved by OCTO; (iii) it is required
          to be disclosed by law, by a competent court or a government body. You shall provide OCTO
          with a reasonable opportunity to review the disclosure and to interpose its own objection to
          the disclosure of the relevant Confidential Information.
        </Trans>
      </p>
      <p>
        <Trans>
          The obligations on keeping the Confidential Information will remain in force during the
          Terms of Use and within 5 years after the termination of the Terms of Use.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Notices. Any notice provided to OCTO pursuant to these Terms of Use should be sent to support@octobrowser.net.">
          <strong>Notices.</strong> Any notice provided to OCTO pursuant to these Terms of Use should
          be sent to <a href={`mailto:${SUPPORT_EMAIL_ROUTE}`}>{SUPPORT_EMAIL_ROUTE}</a>.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Entire Agreement. These Terms of Use set forth the parties’ entire agreement regarding the subject matter of this Terms of Use and supersedes all prior and contemporaneous discussions between the parties.">
          <strong>Entire Agreement.</strong> These Terms of Use set forth the parties’ entire
          agreement regarding the subject matter of this Terms of Use and supersedes all prior and
          contemporaneous discussions between the parties.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Severability. In case anyone or more of the provisions contained in these Terms of Use will, for any reason, be held to be invalid, illegal, or unenforceable in any respect, such invalidity, illegality or unenforceability will not affect the other provisions of these Terms of Use, and these Terms of Use will be construed as if such invalid, illegal or unenforceable provision had never been contained herein.">
          <strong>Severability.</strong> In case anyone or more of the provisions contained in these
          Terms of Use will, for any reason, be held to be invalid, illegal, or unenforceable in any
          respect, such invalidity, illegality or unenforceability will not affect the other
          provisions of these Terms of Use, and these Terms of Use will be construed as if such
          invalid, illegal or unenforceable provision had never been contained herein.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Waiver. A waiver of any right or remedy under this Terms of Use or by law is only effective if given in writing and shall not be deemed a waiver of any subsequent breach or default.">
          <strong>Waiver.</strong> A waiver of any right or remedy under this Terms of Use or by law
          is only effective if given in writing and shall not be deemed a waiver of any subsequent
          breach or default.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Assignment. These Terms of Use, and your rights and obligations herein, may not be assigned by you without OCTO’s prior written consent, and any attempted assignment in violation of the foregoing will be null and void. OCTO may assign its rights obligations in connection with a merger, acquisition, reorganization or sale of all or substantially all of its assets, or other operation of law, without your consent. These Terms of Use shall be binding upon assignees.">
          <strong>Assignment.</strong> These Terms of Use, and your rights and obligations herein, may
          not be assigned by you without OCTO’s prior written consent, and any attempted assignment in
          violation of the foregoing will be null and void. OCTO may assign its rights obligations in
          connection with a merger, acquisition, reorganization or sale of all or substantially all of
          its assets, or other operation of law, without your consent. These Terms of Use shall be
          binding upon assignees.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Governing Law. These Terms of Use shall be governed by and construed and interpreted in accordance with the substantive laws of the UK, without giving effect to any conflicts of law rule or principle that might require the application of the laws of another jurisdiction. Unless otherwise agreed in writing, all disputes relating to these Terms of Use (excepting any dispute relating to intellectual property rights) shall be subject to final and binding arbitration in London, UK, with the losing party paying all costs of arbitration.">
          <strong>Governing Law.</strong> These Terms of Use shall be governed by and construed and
          interpreted in accordance with the substantive laws of the UK, without giving effect to any
          conflicts of law rule or principle that might require the application of the laws of another
          jurisdiction. Unless otherwise agreed in writing, all disputes relating to these Terms of
          Use (excepting any dispute relating to intellectual property rights) shall be subject to
          final and binding arbitration in London, UK, with the losing party paying all costs of
          arbitration.
        </Trans>
      </p>
      <p>
        <Trans>
          In no event shall any claim, action, or proceeding brought by either Party related in any
          way to Software be commenced more than one (1) years after the cause of action arose.
        </Trans>
      </p>
    </StaticPage>
  );
};

export default Terms;
